.glitch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.glitch::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 50vh;
    background-color: #595c5e;
    top: 0vh;
    animation: uplift 2s 0.5s forwards;
}

@keyframes uplift {
    0% {
        top: 0;
    }

    100% {
        top: -50vh;
    }
}

.glitch::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 50vh;
    background-color: #595c5e;
    bottom: 0vh;
    animation: downlift 2s 0.5s forwards;
}

@keyframes downlift {
    0% {
        bottom: 0;
    }

    100% {
        bottom: -50vh;
    }
}

.glitch svg {
    width: 10vw;
    height: auto;
    animation: big 2s 2s forwards;
}

@keyframes big {
    0% {
        width: 10vw;
    }

    100% {
        width: 40vw;
    }
}

.glitchZ {
    fill: #090d1d;
    animation: glitchZ .5s 2.2s forwards, glitchEffect .5s 2.2s forwards
}

@keyframes glitchZ {
    0% {
        fill: #050816;
    }

    100% {
        fill: white;
    }
}

.glitchA {
    fill: #090d1d;
    animation: glitchA .5s 2.4s forwards, glitchEffect .5s 2.4s forwards
}

@keyframes glitchA {
    0% {
        fill: #050816;
    }

    100% {
        fill: white;
    }
}

.glitchI {
    fill: #090d1d;
    animation: glitchI .5s 2.6s forwards, glitchEffect .5s 2.6s forwards;
}

@keyframes glitchI {
    0% {
        fill: #050816;
    }

    100% {
        fill: white;
    }
}

.glitchN {
    fill: #090d1d;
    animation: glitchN .5s 2.8s forwards, glitchEffect .5s 2.8s forwards;

}

@keyframes glitchN {
    0% {
        fill: #050816;
    }

    100% {
        fill: white;
    }
}

@keyframes glitchEffect {
    0% {
        fill: #050816;
        filter: drop-shadow(0 0 0px #050816);
    }

    100% {
        fill: white;
        filter: drop-shadow(0 0 0.3vw #0071ff);
    }
}
