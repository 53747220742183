.contactComp svg {
    transform-origin: center;
    animation: rotate 10s infinite linear;
    cursor: pointer;
    width: 181px;
    height: 142px;
    display: block;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.arrow {
    transform-origin: center;
    transform: rotate(0deg);
    animation: rotaterev 10s infinite reverse;
}

@keyframes rotaterev {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.contactComp svg:hover circle {
    fill: white;
    transition-duration: 0.5s;
}

.contactComp svg:hover path {
    fill: #F1993F;
    transition-duration: 0.5s;
}

@media screen and (max-width:1024px) {
    .contactComp svg {
        width: 100px;
        height: auto;
    }
}