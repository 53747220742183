body {
  cursor: none;
}

.neon-cursor {
  position: absolute;
  z-index: 1000;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.2s ease-out;
  box-shadow: 0 0 10px #0071ff, 0 0 20px #0071ff, 0 0 30px #0071ff, 0 0 40px #0071ff;
}

.neon-glow {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 10px #0071ff, 0 0 20px #0071ff, 0 0 30px #0071ff, 0 0 40px #0071ff;
  opacity: 0;
  animation: neon-glow 1s infinite alternate;
}

.neon-cursor svg {
  transform: rotate(-135deg);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1vw;
  height: auto;
}

@keyframes neon-glow {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(5);
  }
}